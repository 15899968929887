import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";


const Footer = () => {
	return (
		<footer>
			© 2022 All Rights Reserved{" "}
            <a href="https://www.linkedin.com/in/luismiguelluna/">
				<FontAwesomeIcon icon={faLinkedin} size="2x" />
			</a>{" "}
			<a href="https://github.com/LML013">
				<FontAwesomeIcon icon={faGithub} size="2x" />
			</a>{" "}
			<a href="mailto:luismiguelluna@gmail.com">
				<FontAwesomeIcon icon={faEnvelope} size="2x" />
			</a>
		</footer>
	);
};

export default Footer;
