import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import Card from "../components/UI/Card";
import styles from './Project.module.css'

const Project = (props) => {
	const gitHubLinkPresent = () => {
		if (props.gitHubLink == null) {
			return false;
		}
		return true;
	};

	return (
		<Card className={styles.project}>
			<a href={props.link}>
				<h3>{props.title}</h3>
			</a>
			<p>
				{props.desc}{" "}
				{gitHubLinkPresent() && (
					<a href={props.gitHubLink}>
						<FontAwesomeIcon icon={faGithub} />
					</a>
				)}
			</p>
		</Card>
	);
};

export default Project;
