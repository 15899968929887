import React from "react";
import classes from './Skills.module.css'

const Skills = () => {
	return (
		<section className={classes.skills}>
			<h2>Skills Improvement</h2>
			Always looking for new things to learn.
			<h3>Currently Learning</h3>
			<p>
				<a href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/">
					<label htmlFor="lesson">
						React - The Complete Guide (incl Hooks, React Router, Redux)
					</label>
				</a>{" "}
				<progress id="lesson" value="45" max="100">
					{" "}
					42%{" "}
				</progress>
			</p>
			<h3>Previous Learning</h3>
		</section>
	);
};

export default Skills;
