import React from "react";
import styles from "./About.module.css";

const About = () => {
	return (
		<section className={styles.about}>
			<h2>About</h2>
			<p>Hello there!</p>{" "}
			{/*Going to add General Kenobi hover tooltip joke here, if you're seeing this comment you knew that was coming :) */}
			<p>
				I'm a developer in Phoenix, AZ with a background in mostly Python,
				and JavaScript and I'm curently learning React. If you have any projects or work
				you think might be of interest to me, send me an{" "}
				<a href="mailto:luismiguelluna@gmail.com">email</a> and we can talk.
				Thanks for visiting!
			</p>
		</section>
	);
};

export default About;
