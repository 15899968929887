import React from "react";
import "./App.css";

import Footer from "./components/Layout/Footer";
import Project from "./Projects/Project";
import About from "./components/About";
import Skills from "./components/Skills/Skills";

function App() {
	return (
		<div className="App">
			<header><h1>Luis Miguel Luna</h1></header>
			<About />
			<section>
				<h2>Portfolio</h2>
				<Project
					link="http://luismiguelluna.com"
					title="LuisMiguelLuna.com"
					desc="This website. Currently being built with HTML, CSS, JS, and React. Deployed using GitHub and Netlify."
					gitHubLink="https://github.com/LML013/lml-home"
				/>
				<Project
					link="http://greensabergames.com"
					title="Green Saber Games"
					desc="Tabletop gaming blog &amp; content hub made with Wordpress."
				/>
				<Project
					link="https://jovial-goodall-ddb32a.netlify.app/"
					title="Loruki"
					desc="An implementation of Brad Traversy's Build a Responsive Website | HTML, CSS Grid, Flexbox &amp; More tutorial video. Deployed using GitHub and Netlify."
					gitHubLink="https://github.com/LML013/loruki-website"
				/>
			</section>
			<Skills />
			<Footer />
		</div>
	);
}

export default App;
